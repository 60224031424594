<template lang="pug">
  #formSelectSeller
    b-row
      b-col(cols="12")
        multiselect(
          placeholder="Seleccionar cliente",
          v-model="sellerSelected"
          :options="sellers",
          track-by="id"
          label="full_name"
        )
        b-button(variant="primary", @click="setSeller()", :disabled="!sellerSelected", block).mt-2 Asignar Cliente
      b-col(cols="12").pt-2
        b-alert(show) Si el cliente no se encuentra en la lista ingresarlo en el informulario.
    b-overlay#loading(:show="show", opacity="0.7", no-wrap="")
      template(v-slot:overlay="")
        .text-center
          b-spinner(variant="info")
          span.d-block.text-info Espere un momento...
</template>
<script>
import Multiselect from "vue-multiselect";

export default {
  name:"FormSelectSeller",
  components: {
    Multiselect
  },
  props:[
    "sellers"
  ],
  data() {
    return {
      product: 0,
      seller: "",
      sellerSelected : "",
      show: false
    }
  },
  mounted() {
    this.product = this.$parent.$parent.$parent.product
  },
  methods: {
    async setSeller () {
      if(this.sellerSelected) {
        await this.getSeller(this.sellerSelected.id)
        console.log("desde componente", this.seller)
        this.$emit("setSeller", this.seller)
        this.$bvModal.hide("modal-associated-seller");
      }
    },
    async getSeller (idSeller) {
      if(idSeller) {
        this.loading();
        try {
          const { data } = await this.$axios.get(`/api/v1/seller/${idSeller}`)
          console.log("data", data )
          const { id, full_name, phone_1, phone_2, email } = await data.data
          const seller =  {
            id: id,
            full_name: full_name,
            phone_1: phone_1,
            phone_2: phone_2,
            email: email
          }
          this.seller = seller
          this.$swal({
            title: "Asignación de cliente",
            text: data.message,
            icon: "success",
            confirmButtonClass: "btn btn-outline-primary",
            confirmButtonText: "Ok",
            buttonsStyling: false
          })
          this.show = false
        } catch (error) {
          console.error(error)
          this.show = false
        }
      }
    },
    loading(){
      this.show = true
      const height = window.outerHeight
      window.scrollTo({top:(height/2)})
    },
  }
}
</script>