<template lang="pug">
#my-products
	b-row
		b-col(cols="12")
			h2 Mis vehículos
			p.text-secondary
				| En esta sección prodras agregar todos tus vehículos, asi como tambien
				br
				| podras editarlos y destacarlos para tener una mmejor visualización y muchas otras cosas más.
	b-tabs(content-class="mt-3")
		.d-flex.justify-content-center.my-5(v-if="loading")
			b-spinner(variant="info", label="Loading...")
		b-tab(title='En Venta', active='', v-else)
			#stock Vehículos 
				span(v-text="stock")
			b-row
				b-col
					router-link.btn-add-car(to="/new-products", title="Agregar Vehículos")
						span Agregar
						font-awesome-icon(icon="plus")
						span  Vehículos
			draggable(v-model="productsForSale" group="vehicles" @start="drag=true" @end="drag=false" @change="updatePosition")
				b-row.mb-2(v-for="(product, index) in productsForSale", :key="index")
					b-col(v-if="product.vehicles_products")
						b-card.product(
							:img-src="getThumbnail(product.images)"
							:img-alt="product.title"
							:bg-variant="isActive(product.sold,'light','')"
							img-left
							v-bind:class="isActive(product.sold,'bg-sale','bg-light')"
						)
							b-row.h-100.d-flex.align-items-center
								b-col(cols="9", md="3")
									.info-price(v-if="product.price") ${{ product.price | formatNumber }}
									.info-price(v-else) SIN PRECIO
									.info-title.subtitle  {{product.vehicles_products.brand.name}} {{product.vehicles_products.modelo.name}} {{product.vehicles_products.version}} {{product.vehicles_products.year}}
								b-col.d-none.d-md-flex.flex-column.item-info
									div ID
									.subtitle {{ product.id.toString().padStart(6, "0") }}
								b-col.d-none.d-md-flex.flex-column.item-info
									div Publicado
									.subtitle {{ $moment(product.created_at, "DD/MM/YYYY", "es", false).format("DD/MM/YYYY") }}
								b-col.text-sm-left.text-md-center.pt-sm-1(cols="12" md="5")
									b-button.mx-1.btn-action-admin(
										variant="outline-secondary"
										v-b-tooltip.hover
										title="Editar Vehículo"
										@click='showEditModal(product)'
									)
										font-awesome-icon(icon="edit")
									b-button.mx-1.btn-action-admin(
										v-if="isAdmin"
										:variant="isActive(product.sold,'info','outline-info')"
										:title="isActive(product.sold,'Desmarcar como Vendido','Marcar como Vendido')"
										v-b-tooltip.hover
										@click="toggleSold(index, product)"
									)
										font-awesome-icon(icon="tag")
									b-button.mx-1.btn-action-admin(
										:variant="isActive(!product.state,'warning','outline-warning')"
										v-b-tooltip.hover
										title="Bloquear publicación"
										@click="toggleState(index, product)"
									)
										font-awesome-icon(icon="ban")
									b-button.mx-1.btn-action-admin(
										variant="outline-danger"
										v-b-tooltip.hover
										title="Eliminar publicación"
										@click='removeProduct(index, product.id)'
									)
										font-awesome-icon(icon="trash-alt")
									b-button.mx-1.btn-action-admin(
										v-if="isAdmin"
										variant="outline-mhmotors"
										name="arrow-up"
										v-b-tooltip.hover
										title="Subir publicación"
										:disabled="index==0"
										@click="move(index)"
									)
										font-awesome-icon(icon="arrow-alt-circle-up")
										span.d-none.d-lg-inline-block.pl-1 Subir
					b-col.plans(cols='12')
					b-collapse.mt-2(:id="'product-'+product.id")
						b-row.mb-2
							b-col(cols='6', lg='3', v-for='plan in plans', v-bind:key='plan.id')
								b-link.plans-link(@click='setPlan(plan.id)')
									b-card(
										:bg-variant='plan.className'
										text-variant='white'
										title='Destácado'
										:sub-title="'en tú '+plan.title"
									)
										b-card-text(v-html='plan.description')
									b-card.price(:bg-variant='plan.className' text-variant='white')
										b-card-text $ {{ plan.price | formatNumber }}
							b-col.mt-4(cols='12')
								b-form-radio-group#btn-radios-2(
									v-model='days'
									:options='daysOptions'
									buttons=''
									button-variant='outline-orange'
									name='radio-btn-outline'
								)
								b-button(variant='orange ml-1')
									i.icon-shopping-cart
									|  Agregar a carro
			infinite-loading(
				ref="InfiniteLoading"
				:identifier="infiniteId"
				@infinite="infiniteHandler"
				spinner="spiral"
				force-use-infinite-wrapper="true"
			)
				template(slot="no-more") No tienes mas vehículos publicados
				template(slot="no-results") No tienes vehículos publicados
				template(slot="error" slot-scope="{ trigger }") Error al cargar vehiculos, click 
					a(href="javascript:;" @click="trigger") aquí para volver a intentarlo
		//b-tab(title='Vendidos')
			b-table(striped='', hover='', :fields='fields')
	b-modal#modal-edit-vehicle(size="lg", title="Edición de vehículo" hide-footer)
		edit-vehicle(
			v-model='currentProduct',
			:regions="regions",
			:sellers="sellers",
			:brands="brands"
			:bodyworks="bodyworks"
		)
	b-modal#modal-associated-seller(v-if="isAdmin" title="Agregar Cliente a Vehiculo" hide-footer)
		form-select-seller(:sellers="sellers" @setSeller="sellerSelected = $event")
		form-create-seller(@createSeller="sellerAssociated = $event")
	b-modal#modal-view-seller(v-if="isAdmin" title="Cliente asociado Vehiculo" ok-only)
		detail-seller
	b-modal#modal-created-seller(v-if="isAdmin" title="Nuevo cliente" hide-footer)
		form-create-seller(@createSeller="sellerCreated = $event")
	//quick-entry(v-if="isAdmin" @createQuickProduct="productQuickCreated = $event" :regions="regions")
	b-overlay#loading(:show="show", opacity="0.7", no-wrap="")
		template(v-slot:overlay="")
			.text-center
				b-spinner(variant="info")
				span.d-block.text-info Espere un momento...
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import InfiniteLoading from "vue-infinite-loading";
import FormSelectSeller from "@/components/seller/FormSelect";
import FormCreateSeller from "@/components/seller/FormCreate";
import DetailSeller from "@/components/seller/Detail";
import EditVehicle from "@/components/dashboard/EditVehicle";
//import QuickEntry from "@/components/product/QuickEntry";
import draggable from "vuedraggable";

Array.prototype.unique = (function (a) {
  return function () {
    return this.filter(a);
  };
})(function (a, b, c) {
  return c.indexOf(a, b + 1) < 0;
});

export default {
  name: "MyProducts",
  components: {
    DetailSeller,
    EditVehicle,
    FormSelectSeller,
    FormCreateSeller,
    InfiniteLoading,
    //QuickEntry,
    draggable,
  },
  filters: {
    truncate: function (text, length, suffix) {
      return text.substring(0, length) + suffix;
    },
    formatNumber: function (value) {
      if (!value) return "";
      value = value.toString();
      value = value.replace(/\./g, "");
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
  data() {
    return {
      currentProduct: "",
      currentSeller: null,
      days: 0,
      daysOptions: [
        { text: "7 Días", value: "7" },
        { text: "15 Días", value: "15" },
        { text: "30 Días", value: "30" },
      ],
      fields: ["Producto", "Precio"],
      infiniteId: +new Date(),
      page: 1,
      plans: [
        {
          id: 1,
          title: "ciudad",
          description: "Muestra tu aviso destácado en tú ciudad.",
          price: 8000,
          className: "lightblue",
        },
        {
          id: 2,
          title: "region",
          description: "Muestra tu aviso destácado en tú region.",
          price: 10900,
          className: "lightblue-2",
        },
        {
          id: 3,
          title: "pais",
          description: "Muestra tu aviso destácado en tú pais.",
          price: 15900,
          className: "primary",
        },
      ],
      product: 0,
      productKey: 0,
      productQuickCreated: "",
      productsForSale: [],
      productsSold: [],
      sellerAssociated: null,
      sellerCreated: null,
      sellerSelected: null,
      sellers: [],
      show: false,
      stock: 0,
      urlImg: `${this.$axios.defaults.baseURL}`,
      visible: false,
    };
  },
  created() {
    this.getRegions();
  },
  mounted() {
    this.getStock();
    this.getSellers();
  },
  computed: {
    ...mapState("auth", ["isAdmin"]),
    ...mapState(["loading"]),
    ...mapState("region", ["regions"]),
    ...mapState("bodywork", ["bodyworks"]),
    ...mapState("brand", ["brands"]),
  },
  methods: {
    ...mapMutations(["SHOW_LOADING", "HIDE_LOADING"]),
    ...mapActions("region", ["getRegions"]),
    isActive(state, value_true, value_false) {
      return state ? value_true : value_false;
    },
    async showEditModal(product) {
      this.currentProduct = await product;
      this.$bvModal.show("modal-edit-vehicle");
    },
    addSellerModal(key, product) {
      this.$bvModal.show("modal-associated-seller");
      this.productKey = key;
      this.product = product;
    },
    viewSellerModal(seller) {
      this.$bvModal.show("modal-view-seller");
      this.currentSeller = seller;
    },
    async getSellers() {
      try {
        const {
          data: { data: sellers },
        } = await this.$axios.get("/api/v1/seller");
        this.sellers = sellers;
      } catch (error) {
        console.log(error);
      }
    },
    async getStock() {
      const {
        data: { stock },
      } = await this.$axios.get("/api/v1/products/stock");
      this.stock = stock;
    },
    getThumbnail(images) {
      if (Array.isArray(images) && images.length > 0) return images[0].thumbnail;
      else return "https://api.mhmotors.cl/images/no_image_thumb.jpg";
    },
    infiniteHandler($state) {
      try {
        this.SHOW_LOADING();
        this.$axios.get("/api/v1/my-products", { params: { page: this.page } }).then(
          ({
            data: {
              data: { data: products },
            },
          }) => {
            products.filter((product) => {
              let accessories;
              if (product.vehicles_products.accessories != "null")
                accessories = JSON.parse(product.vehicles_products.accessories);
              product.vehicles_products.accessories = accessories
                ? accessories.map((accessory) => accessory.id)
                : [];
              return product;
            });

            const arrayProducts = products;

            if (arrayProducts.length) {
              this.page += 1;
              this.productsForSale.push(...arrayProducts);
              $state.loaded();
            } else {
              $state.complete();
            }
          }
        );
      } catch (e) {
        console.log(e);
        this.$bvToast.toast("Problemas al cargar sus vehículos", {
          title: "Error de carga desde API",
          toaster: "b-toaster-bottom-right",
          variant: "danger",
          solid: true,
        });
      } finally {
        this.HIDE_LOADING();
      }
    },
    removeProduct(key, product_id) {
      this.$swal({
        title: "Eliminar Producto",
        text: "Estas seguro(a) de eliminar este producto",
        icon: "warning",
        confirmButtonClass: "btn btn-outline-danger",
        cancelButtonClass: "btn btn-outline-secondary ml-2",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          this.$axios
            .delete(`/api/v1/product/${product_id}`)
            .then((res) => {
              this.$delete(this.productsForSale, key);
              this.$swal({
                title: "Eliminación de producto!",
                text: res.data.message,
                icon: "success",
                confirmButtonClass: "btn btn-outline-primary px-5",
                confirmButtonText: "Ok",
                buttonsStyling: false,
              });
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    setPlan(plan_id) {
      alert(`Plan: ${plan_id}`);
    },
    sale() {
      console.log("sale");
    },
    async setSellerToProduct(seller) {
      try {
        await this.$axios.put(`/api/v1/product/seller`, {
          id: this.product,
          seller_id: seller.id,
        });
      } catch (error) {
        console.error(error);
      }
    },
    async toggleState(index, product) {
      try {
        await this.$axios.post(`/api/v1/product/state`, {
          id: product.id,
          state: !product.state,
        });
        this.productsForSale[index].state = !product.state;
        this.$bvToast.toast("Se ha cambiado el estado del vehículo", {
          title: "Cambio de estado",
          variant: "success",
          toaster: "b-toaster-bottom-right",
          solid: true,
        });
      } catch (error) {
        this.$bvToast.toast("Problemas al cambiar estado de producto :(", {
          title: "Error de guardado en API",
          variant: "danger",
          toaster: "b-toaster-bottom-right",
          solid: true,
        });
        console.error(error);
      }
    },
    async toggleSold(index, product) {
      try {
        const { data } = await this.$axios.post(`/api/v1/product/sold`, {
          id: product.id,
          sold: !product.sold,
        });
        this.productsForSale[index].sold = !product.sold;
        this.$bvToast.toast("Se ha cambiado el estado de venta del vehículo", {
          title: "Cambio de estado",
          variant: "success",
          toaster: "b-toaster-bottom-right",
          solid: true,
        });
      } catch (error) {
        this.$bvToast.toast("Problemas al cambiar estado de producto :(", {
          title: "Error de guardado en API",
          variant: "danger",
          toaster: "b-toaster-bottom-right",
          solid: true,
        });
        console.error(error);
      }
    },
    move(from) {
      this.productsForSale.splice(0, 0, this.productsForSale.splice(from, 1)[0]);
      this.updatePosition();
    },
    async totalIds() {
      try {
        const { data } = await this.$axios.get(`/api/v1/product/total`);
        return data;
      } catch (error) {
        this.$bvToast.toast("Problemas al obtener el total de ids :(", {
          title: "Error de carga desde API",
          variant: "danger",
          toaster: "b-toaster-bottom-right",
          solid: true,
        });
        console.error(error);
        this.show = false;
      }
    },
    async updatePosition() {
      try {
        this.show = true;
        const products_ids = this.productsForSale.map((product) => product.id);
        const products_total_ids = await this.totalIds();
        const products_filtered = products_total_ids.filter(
          (id) => !products_ids.includes(id)
        );
        const products_concat = products_ids.concat(products_filtered);
        this.savePosition(products_concat);
      } catch (errors) {
        this.show = false;
        console.error(errors);
      }
    },
    async savePosition(products_ids) {
      try {
        const { data } = await this.$axios.post(`/api/v1/product/position`, {
          positions: JSON.stringify(products_ids),
        });
        this.show = false;
        this.$swal({
          title: "Posicion de producto",
          text: data.message,
          icon: "success",
          timer: 3000,
          confirmButtonClass: "btn btn-outline-success",
          confirmButtonText: "Ok",
          buttonsStyling: false,
        });
      } catch (error) {
        this.show = false;
        this.$bvToast.toast("Problemas al destacado producto :(", {
          title: "Error de guardado en API",
          variant: "danger",
          toaster: "b-toaster-bottom-right",
          solid: true,
        });
        console.error(error);
      }
    },
  },
  watch: {
    sellerSelected: function (seller) {
      if (seller)
        this.productsForSale[this.productKey] = Object.assign(
          {},
          this.productsForSale[this.productKey],
          { seller: seller, seller_id: seller.id }
        );
      this.setSellerToProduct(seller);
      this.$refs.InfiniteLoading.stateChanger.reset();
      this.seller = null;
    },
    sellerAssociated: function (seller) {
      this.$bvModal.hide("modal-associated-seller");
      if (seller) this.sellers.push(seller);
      this.productsForSale[this.productKey] = Object.assign(
        {},
        this.productsForSale[this.productKey],
        { seller: seller, seller_id: seller.id }
      );
      this.setSellerToProduct(seller);
      this.$refs.InfiniteLoading.stateChanger.reset();
      this.sellerAssociated = null;
    },
    sellerCreated: function (seller) {
      this.$bvModal.hide("modal-created-seller");
      if (seller) this.sellers.push(seller);
      this.sellerCreated = null;
    },
    productQuickCreated: function (product) {
      this.page = 1;
      this.productsForSale = [];
      this.infiniteId += 1;
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/_variables";

#my-products {
  margin-bottom: 120px;
  .product {
    .card {
      border-radius: 10px;
      &-img-left {
        width: 130px;
        height: auto;
        border-radius: 10px 0 0 10px;
        @media (max-width: 576px) {
          width: 100px;
        }
      }
      &-body {
        padding: 10px;
        .subtitle {
          color: #90a4ae;
          max-width: 300px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .info-price {
          font-weight: 700;
          font-size: 1.25rem;
        }
        .info-title {
          width: 100%;
          line-height: normal;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      &-title {
        font-size: 1rem;
        font-weight: 700 !important;
      }
    }
  }
  .btn-action-admin {
    border-radius: 0.5rem;
    @media (max-width: 576px) {
      font-size: 0.8rem;
    }
  }
  .card {
    border-radius: 10px;
    &-img-left {
      width: 80px;
      height: 100%;
      border-radius: 10px 0 0 10px;
    }
    &-body {
      padding: 10px;
      .subtitle {
        color: #90a4ae;
        max-width: 300px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        @media (max-width: 576px) {
          max-width: 250px !important;
        }
      }
      .info-price {
        font-weight: 700;
        font-size: 1.25rem;
        @media (max-width: 576px) {
          font-size: 1rem !important;
        }
      }
      .info-title {
        width: 100%;
        line-height: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    &-title {
      font-size: 1rem;
      font-weight: 700;
    }
  }
  .plans {
    .card {
      border: 0;
      border-radius: 10px;
      &-title {
        margin-top: 20px;
        font-size: 1.5rem;
        font-weight: 400;
      }
      &-subtitle {
        color: white !important;
        font-size: 1.4rem;
        font-weight: 400;
      }
      &.bg {
        &-lightblue {
          background-color: #7cccff;
        }
        &-lightblue-2 {
          background-color: #02aeff;
        }
        &-lightblue,
        &-lightblue-2,
        &-primary {
          &.price {
            margin-top: 10px;
            .card-body {
              padding: 5px 10px;
              text-align: center;
              font-size: 1.1em;
              font-weight: 700;
            }
          }
        }
      }
    }
    &-link {
      text-decoration: none;
    }
  }
}
.sweet-alert button.confirm {
  outline: none !important;
}
.btn-outline-danger:focus,
.btn-outline-danger.focus,
.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: none !important;
}
.btn-big {
  padding-left: 60px;
  padding-right: 60px;
}

.footer-highlight {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 117.42px;
  z-index: 10;
  background-color: #fff;
  box-shadow: 0 -2px 6px 0 rgba(37, 50, 56, 0.1);
  color: #253238;
  height: 100px;
}
.btn-container {
  display: flex;
  align-items: stretch;
  align-content: flex-start;
  height: 80px;
  margin: -10px 0;
  .btn-skew {
    border: 1px solid 1;
    border-radius: 5px;
    margin: 0 1px;
    flex-direction: start;
    display: inline-block;
    transform: skewX(-20deg);
    transition: all 0.25s ease;
    user-select: none;
    width: 24%;
    min-width: 80px;
    &:hover {
      cursor: pointer;
      transform: skew(-20deg) scale(1.1);
      z-index: 10;
      box-shadow: 0 4px 6px 0 rgba(37, 50, 56, 0.32);
    }
    span {
      transform: skew(20deg);
      display: block;
      font-size: 0.8rem;
    }
    svg {
      transform: skew(20deg);
      margin-left: -10px;
      @media (max-width: 576px) {
        margin-left: -5px;
      }
    }
    &.highlight {
      background-color: #30cb09;
      color: white;
    }
    &.sold {
      background-color: #ee0969;
      color: white;
    }
    &.edit {
      background-color: $primary;
      //background-color: #FF6600;
      color: white;
    }
    &.trash {
      background-color: $primary;
      //background-color: #EC302F;
      color: white;
    }
    @media (max-width: 576px) {
      min-width: 20px;
    }
  }
}
.tab-content {
  position: relative;
  #stock {
    position: absolute;
    top: -40px;
    right: 0;
    font-size: 1.2rem;
    font-weight: 700;
  }
}
.btn-add-car {
  display: block;
  background-color: white;
  border: dashed #bbb 4px;
  color: #bbb;
  font-size: 1.3rem;
  padding: 20px;
  text-align: center;
  margin-bottom: 10px;
  border-radius: 10px;
  transition: ease all 0.3s;
  &:hover {
    text-decoration: none;
    border-color: $primary;
  }
  .fa-plus {
    margin: -14px 10px;
    padding: 10px;
    border: dashed;
    border-radius: 50%;
    width: 45px;
    height: 45px;
  }
}
.loading-spiral {
  border-color: $primary !important;
  border-right-color: transparent !important;
}
.infinite-loading-container {
  padding: 30px;
  margin-bottom: 0;
}
</style>
