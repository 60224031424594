<template lang="pug">
#sellerDetail
  table.table.table-bordered.table-striped.table-hover
    tr
      th(width="100px") Nombre
      td {{ seller.full_name }}
    tr
      th Teléfono 1
      td
        a(:href="`tel:${seller.phone_1}`") {{ seller.phone_1 }}
    tr
      th Teléfono 2
      td
        a(:href="`tel:${seller.phone_2}`") {{ seller.phone_2 }}
    tr
      th Email
      td
        a(:href="`mailto:${seller.email}`") {{ seller.email }}
</template>
<script>
  export default {
    name: "sellerDetail",
    data(){
      return {
        seller: {
          type: Object,
          default() {
            return {
              full_name:"",
              phone_1: "",
              phone_2: "",
              email: ""
            }
          }
        }
      }
    },
    mounted(){
      this.seller = this.$parent.$parent.$parent.currentSeller
    }
  }
</script>