<template lang="pug">
.dashboard
  .dashboard-sidebars(sticky="true").d-none.d-sm-block.d-md-block
    Sidebar-user
  .dashboard-content
    b-container
      b-row
        b-col.pt-4
          my-products
    FooterNavbarMobile
</template>
<script>
import { mapActions } from "vuex";
import SidebarUser from "@/components/dashboard/SidebarUser";
import MyProducts from "@/components/dashboard/MyProducts";
import FooterNavbarMobile from "@/components/FooterNavbarMobile.vue";

export default {
  name: "Dashboard",
  components: {
    SidebarUser,
    MyProducts,
    FooterNavbarMobile,
  },
  created() {
    this.checkIsRole();
  },
  methods: {
    ...mapActions("auth", ["checkIsRole"]),
  },
};
</script>
<style lang="scss">
.dashboard {
  min-height: calc(100vh - 196.66px);
}
</style>
